import { Button, Tooltip, Typography } from "@mui/material";
import StatusBadge from "../StatusBadge";

type Props = {
  children?: React.ReactNode;
  title?: string;
  isWindowView?: boolean;
  tooltip?: string;
  isDisabled?: boolean;
  buttonText?: string;
  className?: string;
  status?: string;
  onClick?: () => void;
};

const TableWrapper = ({
  className,
  title,
  tooltip,
  isDisabled,
  buttonText,
  isWindowView,
  children,
  status,
  onClick,
}: Props) => {
  return (
    <div className={`grid col-span-12 ${className}`}>
      {title && (
        <div className="flex items-center gap-2.5 pb-4">
          <Typography variant="h6">{title}</Typography>
          {tooltip}
          {isDisabled || !buttonText ? null : (
            <Button variant="outlined" size="small" onClick={onClick}>
              {buttonText}
            </Button>
          )}
          {status && <StatusBadge status={status} />}
        </div>
      )}
      <div
        style={
          isWindowView
            ? { maxHeight: "415px", overflow: "hidden", overflowY: "auto" }
            : undefined
        }
      >
        {children}
      </div>
    </div>
  );
};

export default TableWrapper;
