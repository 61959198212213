type Props = {
  children: React.ReactNode;
};

const TableHeader = ({ children }: Props) => {
  return (
    <div className="bg-transparent mb-5">
      <div className="flex items-center gap-2 justify-between">{children}</div>
    </div>
  );
};
export default TableHeader;
