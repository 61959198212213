import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Lang } from "~/types";
import { kptnOrange } from "~/styles/theme";

type Props = {
  handleLangChange: (selectedLang: Lang) => void;
  defaultLang: Lang;
};

export default function LangSelect({ handleLangChange, defaultLang }: Props) {
  const [lang, setLang] = React.useState(defaultLang);

  return (
    <FormControl size="small" variant="outlined">
      <Select
        value={lang}
        onChange={(event) => {
          handleLangChange(event.target.value as Lang);
          setLang(event.target.value as Lang);
        }}
        sx={{
          backgroundColor: "transparent",
          borderRadius: "4px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: kptnOrange,
          },
        }}
      >
        <MenuItem dense value={"en"}>
          🇬🇧 EN
        </MenuItem>
        <MenuItem dense value={"de"}>
          🇩🇪 DE
        </MenuItem>
        <MenuItem dense value={"es"}>
          🇪🇸 ES
        </MenuItem>
        <MenuItem dense value={"fr"}>
          🇫🇷 FR
        </MenuItem>
        <MenuItem dense value={"pt"}>
          🇵🇹 PT
        </MenuItem>
      </Select>
    </FormControl>
  );
}
