import { Link } from "@remix-run/react";
import _ from "lodash";
import { getTimestamp } from "./utils";
import StatusBadge from "../../components/StatusBadge";
import { convertDateToString } from "~/utils/convertDateToString";
import { MRT_ColumnDef } from "material-react-table";
import { Check, Cross } from "~/components";
import {
  AccountPreviewFragment,
  SubscriptionFragment,
} from "./fragments.generated";
import { FavoriteRecipe } from "./types";

export const STATUS_OPTIONS = [
  { value: "active", id: 1 },
  { value: "inactive", id: 2 },
  { value: "register", id: 3 },
];

export const acceptedCmsRoles = ["admin", "customerAgent", "analyst"];
export const canDeleteOrModify = ["admin", "customerAgent"];

export const OWNED_ACC_COLS: Array<MRT_ColumnDef<AccountPreviewFragment>> = [
  {
    header: "First Name",
    accessorKey: "firstName",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.firstName}
      </Link>
    ),
  },
  {
    header: "Last Name",
    accessorKey: "lastName",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.lastName}
      </Link>
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/accounts/owned/${original.id}`}>
        {original.email}
      </Link>
    ),
  },
];

export const SUB_HISTORY_COLS: Array<MRT_ColumnDef<SubscriptionFragment>> = [
  {
    header: "Start Date",
    accessorKey: "startDate",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.startDate
          ? getTimestamp(new Date(original.startDate))
          : "n/a"}
      </span>
    ),
  },
  {
    header: "End Date",
    accessorKey: "endDate",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.endDate ? getTimestamp(new Date(original.endDate)) : "n/a"}
      </span>
    ),
  },
  {
    header: "Type",
    accessorKey: "subscriptionType",
    Cell: ({ row: { original } }) => (
      <span>
        {original?.subscriptionType ? (
          <StatusBadge status={original?.subscriptionType} />
        ) : (
          "n/a"
        )}
      </span>
    ),
  },
  {
    header: "Store",
    accessorKey: "store",
  },
  {
    header: "Status",
    accessorKey: "status",
    Cell: ({ row: { original } }) => <StatusBadge status={original?.status} />,
  },
];

export const RECIPE_FAVS_COLS: Array<MRT_ColumnDef<FavoriteRecipe>> = [
  {
    header: "gdocs",
    accessorKey: "gdocs",
    size: 5,
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/recipes/${original.id}`}>
        {original.gdocs}
      </Link>
    ),
  },
  {
    header: "Title",
    accessorKey: "title",
    size: 400,
    Cell: ({ row: { original } }) => (
      <Link className="titleLink" to={`/recipes/${original.id}`}>
        {original.title}
      </Link>
    ),
  },
  {
    header: "Deleted by User",
    accessorKey: "deleted",
    Cell: ({ row: { original } }) => (original.deleted ? <Check /> : <Cross />),
  },
];

export const ADD_ACCOUNT_COLS: Array<MRT_ColumnDef<AccountPreviewFragment>> = [
  { header: "First Name", accessorKey: "firstName", enableColumnFilter: true },
  { header: "Last Name", accessorKey: "lastName", enableColumnFilter: true },
  {
    header: "Email",
    accessorKey: "email",
    enableColumnFilter: true,
  },
  { header: "CMS Role", accessorKey: "cmsRole", enableColumnFilter: false },
  { header: "", accessorKey: "id", Cell: () => null },
];

export const getAccountsCols = (
  cmsRole?: string | null
): Array<MRT_ColumnDef<AccountPreviewFragment>> => [
  {
    header: "First Name",
    accessorKey: "firstName",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.firstName}
        </Link>
      ) : (
        <span>{original.firstName}</span>
      );
    },
  },
  {
    header: "Last Name",
    accessorKey: "lastName",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.lastName}
        </Link>
      ) : (
        <span>{original.lastName}</span>
      );
    },
  },
  {
    header: "CMS Role",
    accessorKey: "cmsRole",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => {
      return (
        <span>
          {_.join(
            original.roles.map((role) => role.name),
            ", "
          )}
        </span>
      );
    },
  },
  {
    header: "Email",
    accessorKey: "email",
    Cell: ({ row: { original } }) => {
      return cmsRole && canDeleteOrModify.includes(cmsRole) ? (
        <Link className="titleLink" to={"/accounts/" + original.id}>
          {original.email}
        </Link>
      ) : (
        <span>{original.email}</span>
      );
    },
  },
  {
    header: "Favorites",
    accessorKey: "favorites",
    enableColumnFilter: false,
    size: 10,
    Cell: ({ row }) => row.original.favorites?.length,
  },
  {
    header: "Subscriptions",
    accessorKey: "subscriptions",
    enableColumnFilter: false,
    size: 10,
    Cell: ({ row }) => row.original.subscriptions?.length,
  },
  {
    header: "Owned Accs",
    accessorKey: "ownedAccountIds",
    enableColumnFilter: false,
    size: 10,
    Cell: ({ row }) => row.original.ownedAccountIds?.length,
  },
  {
    header: "Update Date",
    accessorKey: "updateDate",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => (
      <span>
        {original.updatedAt ? convertDateToString(original.updatedAt) : "n/a"}
      </span>
    ),
  },
  {
    header: "Creation Date",
    accessorKey: "creationDate",
    enableColumnFilter: false,
    Cell: ({ row: { original } }) => (
      <span>
        {original.createdAt ? convertDateToString(original.createdAt) : "n/a"}
      </span>
    ),
  },
];
