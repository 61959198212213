import { useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageDropZone from "./ImageDropZone";
import ImageContainer from "./ImageContainer";
import ConfirmModal from "../Modals/ConfirmModal";
import { sortByFinalDigits } from "../../features/Recipes/Images/utils";
import { Image } from "~/types";
import {
  downloadImage,
  generateDeleteMsg,
} from "~/components/ImagePicker/utils";
import { Typography } from "@mui/material";

export type RecipeImage = Image & {
  fromTemplate?: string;
  previewUrl?: string;
};

type Props = {
  title?: string;
  defaultImages: RecipeImage[];
  isDisabled: boolean;
  isUpdatable?: boolean;
  span?: string;
  className?: string;
  handleDrop: (files: File[]) => Promise<void>;
  handleDelete: (file: RecipeImage) => Promise<void>;
};

const ImageGrid = ({
  title = "Attachments",
  defaultImages = [],
  isDisabled,
  isUpdatable,
  span = "12",
  className = "",
  handleDrop,
  handleDelete,
}: Props) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<RecipeImage>();

  const sortedDefaultImages = sortByFinalDigits(defaultImages);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => handleDrop(acceptedFiles),
  });

  const handleDeleteFile = (image: RecipeImage) => {
    setShowDeleteDialog(false);
    handleDelete(image);
  };

  const handleDeleteClick = (image: RecipeImage) => {
    setShowDeleteDialog(true);
    setImageToDelete(image);
  };

  const handleDownload = (image: RecipeImage) => downloadImage(image);

  return (
    <div className={`my-8 col-span-${span}`}>
      <Typography variant="h6" className="col-span-12 mb-5">
        {title}
      </Typography>
      <div className="flex flex-col gap-6 col-span-12">
        <section className={className}>
          {sortedDefaultImages.map((image: RecipeImage) => (
            <ImageContainer
              key={image.url}
              image={image}
              isDisabled={isDisabled}
              handleDownload={handleDownload}
              handleDeleteClick={handleDeleteClick}
            />
          ))}
        </section>
        {(defaultImages.length < 1 || isUpdatable) && (
          <ImageDropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
          />
        )}
      </div>
      {imageToDelete && (
        <ConfirmModal
          isOpen={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          message={generateDeleteMsg(imageToDelete)}
          handleDelete={() => handleDeleteFile(imageToDelete)}
        />
      )}
    </div>
  );
};

export default ImageGrid;
