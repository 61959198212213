import { useEffect, useState } from "react";
import { ColumnFilter } from "~/utils/tsUtils";

type Props = {
  filters?: ColumnFilter[];
  onFilterChange?: (filterValues: ColumnFilter[]) => void;
};

function useFilterChange({ filters, onFilterChange }: Props) {
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(
    filters || []
  );

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(columnFilters);
    }
  }, [columnFilters]);

  return { columnFilters, setColumnFilters };
}

export default useFilterChange;
