import { ReactNode } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { useWindowDimensions } from "../hooks";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  position?: "top" | "right" | "left" | "bottom";
  styleProp?: { width?: string };
  toggle: (isOpen: boolean) => void;
};

const Drawer = ({
  children,
  position = "right",
  isOpen,
  styleProp = {},
  toggle,
}: Props) => {
  const defaultDrawerWidth =
    (useWindowDimensions()?.width ?? 1000) < 900 ? "90vw" : styleProp?.width;

  const paperProps =
    position === "right" || position === "left"
      ? {
          sx: {
            width: defaultDrawerWidth,
            maxWidth: defaultDrawerWidth ?? "75vw",
            minWidth: defaultDrawerWidth ?? "40vw",
            height: "100%",
          },
        }
      : undefined;

  return (
    <MuiDrawer
      anchor={position}
      open={isOpen}
      PaperProps={paperProps}
      onClose={() => toggle(false)}
    >
      <div className="p-5">{children}</div>
    </MuiDrawer>
  );
};

export default Drawer;
