// app/styles/theme.ts
import { createTheme } from "@mui/material/styles";

export const kptnOrange = "#f35e43";

const theme = createTheme({
  palette: {
    primary: {
      main: kptnOrange,
    },
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: kptnOrange, // Set spinner color to primary color (or use theme.palette.primary.main)
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: kptnOrange, // Default color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: "flex", // Ensures alignment of text and icon
          alignItems: "center", // Center-aligns content vertically
          letterSpacing: "2px",
        },
        contained: {
          display: "flex", // Ensures alignment of text and icon
          alignItems: "center", // Center-aligns content vertically
          "&.Mui-disabled": {
            opacity: 0.5,
            color: "#fff",
            backgroundColor: kptnOrange,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "32px",
          marginBottom: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          minHeight: "56px", // Ensures single-line inputs have 56px height
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#fafafa",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: kptnOrange,
            },
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: kptnOrange,
            },
          },
        },
        input: {
          padding: "12px 14px",
        },
        multiline: {
          minHeight: "56px", // Ensures a minimum height but allows growing
          height: "auto", // Lets it expand as needed
          padding: "6px 0px",
        },
        inputMultiline: {
          height: "auto",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: "56px", // Ensures consistency across single and multiline
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "8px 10px !important", // Padding inside the Autocomplete input
        },
        endAdornment: {
          paddingRight: "8px", // Space between input and dropdown arrow
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: kptnOrange, // Border color when unchecked
          },
          "&.Mui-checked": {
            color: kptnOrange,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: kptnOrange,
          color: "#fff", // Optional: Set text color to white for contrast
        },
        deleteIcon: {
          color: "#fff", // Optional: Set delete icon color to white for contrast
        },
        icon: {
          color: "#fff", // Set color for startIcon
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: kptnOrange, // Label Color on Focus
          },
        },
        asterisk: {
          color: kptnOrange,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Helper Text / Error Messages
        },
      },
    },
  },
});

export default theme;
