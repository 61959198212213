import { MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";

type Props = {
  page?: number;
  pageSize: number;
  onPageChange?: (page: number, size: number) => void;
};

function usePagination({ page, pageSize, onPageChange }: Props) {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: page ? page - 1 : 0,
    pageSize,
  });

  // update page on hook remount
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page ? page - 1 : 0,
    }));
  }, [page]);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  return { pagination, setPagination };
}

export default usePagination;
