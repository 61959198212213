import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useOutletContext } from "@remix-run/react";
import { LayoutContext } from "./MainLayout";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetters,
  getBreadcrumbData,
} from "~/utils/tsUtils";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { useContext } from "react";
import { UserInfoContext } from "~/utils/Providers";
import { getNavlinks } from "~/constants/navLinks";

type Props = {
  title?: string;
  nestedTitle?: string;
  titleLink?: string;
  nestedTitleLink?: string;
  children?: React.ReactNode;
  slugIsId?: boolean;
};

const Navbar = ({
  title = "",
  nestedTitle = "",
  titleLink,
  nestedTitleLink,
  slugIsId = false,
  children,
}: Props) => {
  const [toggleSidebar, isSidebarHidden] = useOutletContext<LayoutContext>();
  const { pathname } = useLocation();
  const { cmsRole } = useContext(UserInfoContext);

  const navlinks = getNavlinks(cmsRole);

  const { baseText, nestedText } = getBreadcrumbData(
    pathname,
    navlinks,
    slugIsId
  );

  return (
    <div
      className={`basic-transition fixed right-0 top-0 z-50 bg-bgColor ${
        isSidebarHidden ? "left-[60px]" : "left-[250px]"
      }`}
    >
      <div className="mx-auto flex h-16 w-[92.5%] items-center justify-between gap-10 border-b-[0.5px] border-solid border-gray-500 py-5">
        <div className="flex gap-4 items-center">
          <button onClick={toggleSidebar} className="hover:text-primary-100 ">
            {isSidebarHidden ? <MenuIcon /> : <MenuOpenIcon />}
          </button>
          <Breadcrumbs
            title={title}
            baseText={baseText}
            titleLink={titleLink}
            nestedText={nestedText}
            nestedTitle={nestedTitle}
            nestedTitleLink={nestedTitleLink}
          />
        </div>
        <div className="flex items-center gap-4">{children}</div>
      </div>
    </div>
  );
};

export default Navbar;

const Breadcrumbs = ({
  baseText,
  title,
  titleLink,
  nestedText,
  nestedTitle,
  nestedTitleLink,
}: {
  baseText: string;
  title?: string;
  titleLink?: string;
  nestedText?: string;
  nestedTitle?: string;
  nestedTitleLink?: string;
}) => {
  return (
    <MUIBreadcrumbs>
      {baseText && (
        <Breadcrumb
          text={capitalizeFirstLetters(baseText)}
          link={`/${baseText.toLowerCase().replace(" ", "")}`}
        />
      )}
      {title && (
        <Breadcrumb text={capitalizeFirstLetters(title)} link={titleLink} />
      )}
      {nestedText && (
        <Breadcrumb
          text={capitalizeFirstLetters(nestedText)}
          link={nestedTitleLink}
        />
      )}
      {nestedTitle && <Breadcrumb text={nestedTitle} />}
    </MUIBreadcrumbs>
  );
};

const Breadcrumb = ({ text, link }: { text: string; link?: string }) =>
  link ? (
    <Link className="hover:underline flex items-center gap-3" to={link}>
      <Typography>{capitalizeFirstLetter(text)}</Typography>
    </Link>
  ) : (
    <Typography>{capitalizeFirstLetter(text)}</Typography>
  );
