import _ from "lodash";
import { DeleteInfo } from "~/types";
import {
  AccountDetailFragment,
  AccountPreviewFragment,
  FavoriteFragment,
  SubscriptionFragment,
} from "./fragments.generated";
import { FavoriteRecipe, RecipeObj } from "./types";

export const getActiveSubscriptionStatus = (
  subscriptionHistory: SubscriptionFragment[]
) => {
  const today = new Date();
  const activeSubscription = subscriptionHistory.find((subscription) => {
    const startDate = new Date(subscription.startDate);
    const endDate = new Date(subscription.endDate);

    return today > startDate && today < endDate;
  });
  return activeSubscription?.status;
};

const getMostRecentlyEndedSubStatus = (
  subscriptionHistory: SubscriptionFragment[]
) => {
  if (subscriptionHistory.length === 0) {
    return undefined;
  }

  const mostRecentlyEndedSub = subscriptionHistory
    .filter((sub) => sub.endDate)
    .sort(
      (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    )[0];

  return mostRecentlyEndedSub?.status;
};

export const getSubscriptionStatus = (
  subscriptionHistory: SubscriptionFragment[]
) => {
  if (!subscriptionHistory || subscriptionHistory.length === 0) return null;

  const activeStatus = getActiveSubscriptionStatus(subscriptionHistory);
  return activeStatus ?? getMostRecentlyEndedSubStatus(subscriptionHistory);
};

export const getFilteredRecipes = (
  recipeOptions: { id: string; label: string }[],
  recipes: FavoriteRecipe[]
) => {
  return _.differenceWith(
    recipeOptions,
    recipes,
    (recipeOption: { id: string; label: string }, recipe: FavoriteRecipe) =>
      recipeOption.id === recipe.id
  );
};

export function getDaysLeft(validUntilTimestamp: string) {
  const currentDate = new Date().getTime();
  const validUntilDate = new Date(validUntilTimestamp).getTime();
  const timeDifference = validUntilDate - currentDate;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export const getTimestamp = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;
};

export const buildRecipeOptions = (recipes: RecipeObj[]) =>
  recipes.map((recipe) => ({
    id: recipe.id ?? "",
    label: `${recipe.gdocs} - ${recipe.title}`,
  }));

export const getDeleteInfo = (acc: AccountPreviewFragment): DeleteInfo => ({
  deleteArgs: [acc.id],
  name: `${acc.firstName} ${acc.lastName}`,
});

export const getRecipeDeleteInfo = (recipe: FavoriteRecipe): DeleteInfo => ({
  deleteArgs: [recipe.id ?? "n/a"],
  name: recipe.title ?? "",
});

export const getFilterParams = (searchParams: URLSearchParams) =>
  ["firstName", "lastName", "email"]
    .filter((id) => searchParams.get(id))
    .map((id) => ({
      id,
      value: searchParams.get(id),
    }));

export const buildAccountFavs = (
  favorites: FavoriteFragment[],
  recipeObjs: RecipeObj[]
): FavoriteRecipe[] => {
  return (favorites || [])
    ?.map((fav) => {
      const recipe = recipeObjs.find((recipe) => recipe.id === fav.recipeId);
      return recipe ? { deleted: fav.deleted, ...recipe } : null;
    })
    .filter(
      (recipe): recipe is RecipeObj & { deleted: boolean } => recipe !== null
    )
    .sort((a, b) => (a.gdocs ?? "").localeCompare(b.gdocs ?? ""));
};

export const getOwner = (account: AccountDetailFragment) => ({
  ownerName: `${account.owners[0].firstName} ${account.owners[0].lastName}`,
  id: account.owners[0].id,
});

export const getDefaultRows = (ownedAccounts: AccountPreviewFragment[]) =>
  Object.fromEntries(ownedAccounts.map((acc) => [acc.id, true]));
