import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Option } from "~/types";
import { clsx } from "clsx";

type Props<CustomOption extends Option> = {
  options: CustomOption[];
  onChange: (value?: CustomOption) => void;
  label?: string;
  className?: string;
  getOptionLabel?: (option: CustomOption) => string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: CustomOption;
  defaultId?: string;
  isOptionEqualToValue?: (option: CustomOption, value: CustomOption) => boolean;
};

function SimpleAutoComplete<CustomOption extends Option = Option>({
  disabled = false,
  ...props
}: Props<CustomOption>) {
  const getOptionLabel = (option: CustomOption) => {
    return option.value;
  };
  const [value, setValue] = React.useState<CustomOption | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <Autocomplete
      className={props.className}
      disabled={disabled}
      defaultValue={props.options.find(({ id }) => id === props.defaultId)}
      value={value ?? props.defaultValue}
      onChange={(_: any, newValue: CustomOption | null) => {
        setValue(newValue);

        if (newValue) {
          props.onChange(newValue);
        } else {
          props.onChange();
        }
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={props.options}
      getOptionLabel={getOptionLabel}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
      isOptionEqualToValue={
        props.isOptionEqualToValue ??
        ((option, value) => option.id === value?.id)
      }
    />
  );
}

export default SimpleAutoComplete;
